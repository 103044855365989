@import '../../styles/components-dependencies';

.ngx-slider {
    .ngx-slider-pointer,
    .ngx-slider-selection {
        background-color: $app-color !important;

        &:focus {
            outline: none;
        }
    }

    .ngx-slider-pointer {
        width: 22px !important;
        height: 22px !important;
        top: -9px !important;

        &:after {
            width: 8px !important;
            height: 8px !important;
            top: 7px !important;
            left: 7px !important;
        }
    }

    .ngx-slider-pointer.ngx-slider-active:after {
        background-color: $app-color !important;
    }
}

span.ngx-slider-span.ngx-slider-bubble.ngx-slider-limit.ngx-slider-floor,
span.ngx-slider-span.ngx-slider-bubble.ngx-slider-limit.ngx-slider-ceil {
    font-size: rem-calc(14px);
    color: $medium-gray;
}

span.ngx-slider-span.ngx-slider-bubble.ngx-slider-model-high,
span.ngx-slider-span.ngx-slider-bubble.ngx-slider-model-value {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    font-weight: bold;
    margin-bottom: rem-calc(24px);
    z-index: 1;
    font-size: rem-calc(12px);
    white-space: normal;
    max-width: 48px;
    min-width: 48px;
    height: rem-calc(30px);

    &:after {
        position: absolute;
        content: "";
        min-width: 55px;
        min-height: 55px;
        border-radius: 50% 50% 0 50%;
        background: $app-color;
        font-weight: bold;
        margin-bottom: 0.8rem;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        z-index: -1;
    }
}
