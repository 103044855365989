// Skin title ////////////////////////////////////////////////////////////////////////////////////////
.block-title {
    .block-title-icon {
        opacity: 0.5;
        font-size: rem-calc(20px);
        margin-right: rem-calc(8px);
    }

    svg {
        color: $app-color;
    }
}

// Skin note & note  /////////////////////////////////////////////////////////////////////////////////////////////

.note {
    font-size: rem-calc(12px);
    font-weight: normal;
    color: $app-color;

    &.error {
        display: flex;
        align-items: center;
        color: $app-color;

        &:before {
            content: "\f05a";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            color: $app-color;
        }

        line-height: rem-calc(14px);
        margin-top: rem-calc(8px);

        &:before {
            content: "\e902";
            font-family: 'izivia-mobile';
            font-weight: 900;
            color: $app-color;
            margin-right: rem-calc(4px);
        }

    }

    &.large {
        font-size: rem-calc(15px);
        margin-bottom: rem-calc(16px);
    }

    &.hidden {
        visibility: visible;
    }

    &.small {
        font-size: rem-calc(14px);
    }

    &.black {
        color: $black;
    }
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: rem-calc(68px);
    font-size: rem-calc(14px);
    background-color: rgba($app-primary, 0.1);
    border-radius: $border-radius;
    font-weight: bold;
    padding: rem-calc(8px 56px) !important;
    background-image: url("/assets/png/info-icon.png");
    background-size: rem-calc(72px);
    background-repeat: no-repeat;
    background-position: -22px 50%;
    @extend %box-shadow;

    &.alert-info {
        background-image: url("/assets/png/info-alert-icon.png");
        background-color: rgba($app-color, 0.1);
        color: $app-error;
        border: rem-calc(2px) solid rgba($app-color, 0.2);
        margin-bottom: rem-calc(16px);
    }
}

// Skin offer title ////////////////////////////////////////////////////////////////////////////////////////

.offer-block-title-color {
    color: $app-primary !important;
}

// Message empty ////////////////////////////////////////////////////////////////////////////////////////////

.message-empty {
    min-height: rem-calc(216px);

    .message-empty-icon {
        width: rem-calc(148px);
    }

    .message-empty-label {
        margin-top: rem-calc(16px);
        font-size: rem-calc(14px);
    }
}

// Fix size of font ////////////////////////////////////////////////////////////////////////////////////////

.font-size-16 {
    font-size: rem-calc(16px);
}

.font-size-14 {
    font-size: rem-calc(14px);
}
