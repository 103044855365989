

.validate {
    .ng-select {
        .ng-select-container {
            border-color: $app-success;
        }
    }
}


.ng-select {
    font-size: 14px;
    font-weight: 400;

    &.loading {
        padding: 0;

        &:before {
            display: none;
        }
    }

    .ng-select-container {
        display: flex;
        width: auto;
        background: white;
        align-items: center;
        border: rem-calc(1px) solid $app-color;
        border-radius: $border-radius;
        padding: rem-calc(8px);
        height: rem-calc(48px);

        &:active,
        &:focus {
            background: rgba($app-color, 0.03);
            color: $app-color;
        }

        &:active,
        &:focus {
            background: rgba($app-color, 0.05);
        }

        &:hover {
            background: rgba($app-color, 0.05) !important;
            color: $app-color;
        }

        input[type='text'] {
            min-height: rem-calc(38px) !important;
            padding-left: rem-calc(14px) !important;
        }

        input[type='text']:focus {
            border: none !important;
            box-shadow: none !important;
            padding-left: rem-calc(14px);
        }

        .ng-value-container {
            display: flex;
            align-items: flex-start;
            margin: auto 0;
        }
    }

    .ng-value {
        display: flex;
        align-items: center;
        margin: 0 8px;

        .ng-value-label {
            &:after {
                display: none;
            }
        }

        .ng-value-icon {
            padding: 0 4px;
            background: rgba($app-secondary, 0.1);
            border-right: 1px solid rgba($app-secondary, 0.5);
            border-radius: 4px 0 0 4px;
        }
    }


    .ng-clear-wrapper {
        top: 0 !important;
        .ng-clear {
            &:after {
                display: none !important;
            }
        }
    }
    .ng-arrow {
        &:after {
            content: '' !important;
            display: none !important;
        }
    }

    &.ng-select-multiple {
        .ng-select-container {
            padding: 0;

            .ng-value-container {
                .ng-placeholder {
                    top: calc(50% - 12px);
                    left: rem-calc(8px);
                }
            }

            &.ng-has-value {
                .ng-value-container {
                    padding-top: rem-calc(8px);
                }
            }
        }
    }
}

.ng-select-single {
    .ng-value-container {
        display: flex;
        align-items: center !important;
        height: 100%;
    }
}

.ng-select:not(.ng-select-single) .ng-value .ng-value-label {
    background: rgba($app-secondary, 0.1);
    padding: 0 4px;
    border-radius: 0 4px 4px 0;
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
    background: none !important;
}

.ng-select .ng-clear-wrapper {
    z-index: 2;
}


.ng-dropdown-panel {
    border: 1px solid $medium-grey;
    box-shadow: 0 3px 10px rgba(#0000, 0.1);
    border-radius: $border-radius;
    background: white;
    left: 0;

    .ng-option {
        padding: rem-calc(8px);
        cursor: pointer;
        font-size: 12px;
        color: $dark-grey !important;

        &.ng-option-marked {
            background-color: rgba($app-secondary, 0.1);
        }

        &:hover {
            background-color: rgba($app-secondary, 0.1);
        }

        .ng-option-label {
            color: $dark-grey;
        }
    }

    .ng-option-selected {
        background: rgba($app-secondary, 0.1);
    }
}
