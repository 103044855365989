app {
    .primary-nav-container {
        background-color: white;
        max-width: 160rem;
        margin: rem-calc(16px) auto;
        height: rem-calc(60px) !important;
        border-top: rem-calc(2px) solid white;

        .primary-nav {
            width: 100%;
            height: 100%;
            padding-left: rem-calc(32px);

            // Menu colors options

            .primary-nav-item {
                margin: 0 rem-calc(1px);
                padding: rem-calc(0 16px);
                text-decoration: none;
                border-radius: 0;
                font-weight: 600;
                font-size: rem-calc(14px);
                background: transparent;
                @extend %rippleEffect;
                @extend %transition;


                // Skin hover & selected link

                &:hover, &.selected {
                    .primary-nav-item-text {
                        border-bottom: 2px solid $app-color;
                        color: $app-color;

                    }
                }

                // Skin disabled

                &.disabled {
                    opacity: 0.6;
                    pointer-events: none;

                    &:hover {
                        background-color: transparent;


                        &:nth-child(even) {
                            background-color: transparent;
                        }
                    }
                }

                &:first-child {
                    margin-left: 0
                }

                &:last-child {
                    margin-right: 0;
                }

                .primary-nav-item-text {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    max-width: 100%;
                    color: $app-primary;
                    border-bottom: 2px solid transparent;
                    padding: 0 rem-calc(8px);
                }
            }
        }
    }

    // Main common style

    .indicators-bloc {
        background-color: $light-grey;
        padding: rem-calc(8px);
    }
}
