.subscription-result-block {
  background-color: $white-grey;
  border: rem-calc(1px) solid $medium-grey;
  border-radius: $border-radius;
  padding: rem-calc(32px);
  margin: rem-calc(16px);

  &.small-space {
    padding-top: rem-calc(0) !important;
    padding-bottom: rem-calc(0) !important;
    margin: 0;
  }

  .subscription-result-block-item {
    display: flex;
    justify-content: center;
    border: rem-calc(1px) solid $app-color;
      box-shadow: $box-shadow;
    border-radius: $border-radius;
    background-color: $white;
    font-size: rem-calc(38px);
    font-weight: 600;
    min-width: rem-calc(180px);
    padding: rem-calc(8px);
    margin: rem-calc(16px);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: -20% 50%;

      .price {
          font-size: rem-calc(38px);
      }
  }
}
