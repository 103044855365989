.pass-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  border: rem-calc(1px) solid $medium-grey;
  border-radius: $border-radius;
  padding: 0 rem-calc(16px);

  .indicator-value {
    font-size: rem-calc(32px);
    color: $black;
    font-weight: bold;
    padding: rem-calc(2px);
  }

  .indicator-arrow {
    color: $black;
    padding: rem-calc(2px);
    font-size: rem-calc(20px);
  }

  .indicator-icon {
    padding: rem-calc(2px);
    font-size: rem-calc(24px);

    &.activate {
      color: $app-success;
    }

    &.order {
      color: $app-warning;
    }

    &.disabled {
      color: $app-error;
    }
  }
}

