// Skin form for login //////////////////////////////////////////////////////////////////////////////

.label-login {
    margin: rem-calc(16px) 0;
    font-weight: bold;

    .label-container {
        padding: rem-calc(8px 16px);
    }

    &.primary {
        .label-container {
            background-color: $app-primary;
            border: rem-calc(1px) solid $app-primary;
            color: $white;
        }
    }

    &.secondary {
        .label-container {
            background-color: $app-secondary;
            border: rem-calc(1px) solid $app-secondary;
            color: $white;
        }
    }

    &.login {
        .label-container {
            &:before {
                content: '\f007';
                font-family: "Font Awesome 5 Free";
                padding-right: rem-calc(16px);
            }
        }
    }

    &.password {
        .label-container {
            &:before {
                content: '\f023';
                font-family: "Font Awesome 5 Free";
                padding-right: rem-calc(16px);
            }
        }
    }

    .remember-password {
        font-weight: normal;
    }

    .input-login {
        border-radius: 0 !important;

        &:focus {
            border: rem-calc(1px) solid $app-primary !important;
            box-shadow: none !important;
            -webkit-box-shadow: 0 0 0 1000px $white-grey inset !important;
        }
    }
}

// Skin Form Group ///////////////////////////////////////////////////////////////////////////////

// Skin label ///////////////////////////////////////////////////////////////////////////////

label {
    color: $app-primary;
    font-weight: normal;
    margin: 0;
    font-size: rem-calc(14px);
    min-height: rem-calc(48px);
    padding: rem-calc(8px);

    // Skin required

    &.required {
        position: relative;

        .labelName {
            display: flex;
            align-items: center;

            &:after {
                content: '*';
                font-family: EDF2020, san-serif;
                color: $app-primary;
                padding-left: 4px;
            }
        }

        .inputContainer {
            position: relative;
            margin: 0;
        }

        input {
            border: 1px solid $app-error;
            background: white !important;
            color: $app-error;

            &::placeholder {
                color: $dark-grey !important;
                font-style: italic;
                background: white;
            }
        }

        .label-choice:after {
            margin-left: rem-calc(4px);
        }

        &.pending {
            span {
                display: flex;
                align-items: center;

                &:after {
                    content: '\f069';
                    font-family: "Font Awesome 5 Free";
                    color: $app-secondary;
                    font-size: rem-calc(10px);
                    margin-left: rem-calc(4px);
                }
            }
        }
    }

    &.optional {
        input {
            border: 1px solid $medium-grey;
        }
    }

    // Skin invalid

    &.invalid {
        background: rgba($app-color, 0.1);

        span, .labelName {
            color: $app-error;

            &:after {
                color: $app-error;
            }
        }
    }

    // Skin validate

    &.validate {
        input,
        select,
        .customFile {
            border-color: $app-success !important;
            color: $app-primary;
            background: white;
        }

        .inputContainer {
            &:after {
                content: none;
            }
        }

        .search-icon {
           color: $app-success !important;
        }

        span, .labelName {
            display: flex;
            align-items: center;
            color: $app-primary;

            span.required {
                margin-left: 4px;
            }
        }

        &.required {
            input {
                border: 1px solid $app-success;
                color: $app-primary;
                background: white;
            }
        }
    }

    // Skin size

    &.small {
        font-size: rem-calc(14px);
        font-weight: normal;
    }
}

input.full-input.ng-invalid.ng-dirty > label {
    background: rebeccapurple !important;
}

input.full-input.ng-invalid.ng-dirty {
    label {
        background: rebeccapurple !important;
    }
}

label {
    &.required {
        input.full-input.ng-invalid.ng-dirty > label {
            content: '*';
            font-family: EDF2020, san-serif;
            color: $app-primary;
        }
    }
}

.margin-label {
    &.all {
        margin: rem-calc(16px) !important;
    }

    &.right {
        margin-right: rem-calc(16px) !important;
    }

    &.left {
        margin-left: rem-calc(16px) !important;
    }

    &.top {
        margin-top: rem-calc(16px) !important;
    }

    &.bottom {
        margin-bottom: rem-calc(16px) !important;
    }

    &.required {
        span:after {
            margin-left: rem-calc(4px);
        }
    }
}

// Skin input ///////////////////////////////////////////////////////////////////////////////

input[type='text'],
input[type='password'],
input[type='number'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='email'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='url'],
input[type='color'],
input[type='file'],
[type=text],
[type=password],
[type=date],
[type=datetime],
[type=datetime-local],
[type=month],
[type=week],
[type=email],
[type=search],
[type=tel],
[type=time],
[type=url],
[type=color],
textarea,
select,
input {
    position: relative;
    color: $app-primary;
    margin: 0 !important;
    padding-left: rem-calc(14px);
    min-height: rem-calc(48px) !important;
    border-radius: $border-radius;
    border-color: $app-color;

    &:active,
    &:focus {
        background: rgba($app-color, 0.03) !important;
    }

    &:hover {
        background: rgba($app-color, 0.05) !important;
        color: $app-color !important;

        &::placeholder {
            color: $app-color !important;
            background: rgba($app-color, 0.01) !important;
        }

        &:disabled {
            background: white !important;
            color: $dark-grey !important;
        }
    }

    &:disabled {
        border-color: $dark-grey;
        color: $dark-grey;
        font-style: italic;
    }

    &::placeholder {
        color: $dark-grey !important;
        font-style: italic;
    }

    // Fix validate color

    .ng-validate {
        border-color: $app-success;
    }


    // Fix autofill color

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px $white-grey inset !important;

        &:focus {
            -webkit-box-shadow: 0 0 0 1000px $white-grey inset !important;
        }
    }

    // Size options

    &.full-input {
        width: 100%;
    }

    &.small {
        min-height: rem-calc(24px) !important;
    }
}

// Skin Input Read Only /////////////////////////////////////////////////////////////////////////////

.input-read-only {
    display: flex;
    align-items: center;
    height: rem-calc(48px);
    width: 80%;
    color: $dark-grey !important;
    font-size: rem-calc(14px);
    font-weight: normal;
    margin-bottom: 0;
}

// Search label /////////////////////////////////////////////////////////////////////////////

.search-label {
    position: relative;

    .search {
        &::placeholder {
            color: $dark-grey;
        }

        &:focus {
            ~ .search-icon {
                color: $app-color;
            }
        }

        @media screen and (min-width: 0 \0
        ) {
            padding-right: rem-calc(32px);
        }
    }

    .search-icon {
        position: absolute;
        right: rem-calc(16px);
        top: rem-calc(16px);
        width: rem-calc(16px);
        color: $app-color;
    }

    // Skin validate

    &.validate {
        input, select {
            border-color: $app-success;
            color: $app-primary;
            background: white;
        }

        .inputContainer {
            &:after {
                content: none;
            }
        }

        .search-icon {
            color: $app-success !important;
        }

        span {
            display: flex;
            align-items: center;
            color: $app-primary;
        }

        &.required {
            input, select {
                border: 1px solid $app-success;
                color: $app-primary;
                background: white;
            }
        }
    }

    // No label option
    &.with-label {
        .search-icon {
            top: rem-calc(54px);
        }
    }
}

// Skin select ///////////////////////////////////////////////////////////////////////////////
select {
    color: $app-primary;
    margin: 0 !important;
    padding: rem-calc(8px 28px 8px 14px);
    min-height: rem-calc(48px) !important;
    border-radius: $border-radius;
    border-color: $app-color;
    background: white;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbHF1ZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMjQxLjcgMTU1LjIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0MS43IDE1NS4yOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzgwODA4MDt9Cjwvc3R5bGU+Cjx0aXRsZT5hcnJvdzwvdGl0bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik01LjQsMjIuOWwxOC0xN2MwLDAsMTMuNS0xMywyNiwwczcyLDcyLDcyLDcybDcxLjUtNzEuNWMwLDAsMTItMTQsMjYtMC41czE3LDE2LjUsMTcsMTYuNXMxMywxMi41LDAsMjYKCXMtMTAyLDEwMS41LTEwMiwxMDEuNXMtMTMuNSwxMi0yNS41LDBMNi43LDQ4LjFDNi43LDQ4LjEtNy42LDM1LjEsNS40LDIyLjl6Ii8+Cjwvc3ZnPgo=');
    background-repeat: no-repeat;
    background-position: 97%;
    background-size: 12px;

    &:active,
    &:focus {
        background: rgba($app-color, 0.03);
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbHF1ZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMjQxLjcgMTU1LjIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0MS43IDE1NS4yOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzgwODA4MDt9Cjwvc3R5bGU+Cjx0aXRsZT5hcnJvdzwvdGl0bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik01LjQsMjIuOWwxOC0xN2MwLDAsMTMuNS0xMywyNiwwczcyLDcyLDcyLDcybDcxLjUtNzEuNWMwLDAsMTItMTQsMjYtMC41czE3LDE2LjUsMTcsMTYuNXMxMywxMi41LDAsMjYKCXMtMTAyLDEwMS41LTEwMiwxMDEuNXMtMTMuNSwxMi0yNS41LDBMNi43LDQ4LjFDNi43LDQ4LjEtNy42LDM1LjEsNS40LDIyLjl6Ii8+Cjwvc3ZnPgo=');
        background-repeat: no-repeat;
        background-position: 97%;
        background-size: 12px;
    }

    &:hover {
        background: rgba($app-color, 0.05);
        color: $app-color !important;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbHF1ZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMjQxLjcgMTU1LjIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0MS43IDE1NS4yOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzgwODA4MDt9Cjwvc3R5bGU+Cjx0aXRsZT5hcnJvdzwvdGl0bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik01LjQsMjIuOWwxOC0xN2MwLDAsMTMuNS0xMywyNiwwczcyLDcyLDcyLDcybDcxLjUtNzEuNWMwLDAsMTItMTQsMjYtMC41czE3LDE2LjUsMTcsMTYuNXMxMywxMi41LDAsMjYKCXMtMTAyLDEwMS41LTEwMiwxMDEuNXMtMTMuNSwxMi0yNS41LDBMNi43LDQ4LjFDNi43LDQ4LjEtNy42LDM1LjEsNS40LDIyLjl6Ii8+Cjwvc3ZnPgo=');
        background-repeat: no-repeat;
        background-position: 97%;
        background-size: 12px;

        &::placeholder {
            color: $app-color !important;
        }
    }

    &::placeholder {
        color: $dark-grey !important;
        font-style: italic;
    }

    &:disabled {
        border-color: $disable;
        background: white;
        font-style: italic;
        color: $disable;

        .option {
            color: $disable;
        }
    }

    // Fix autofill color

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px $white-grey inset !important;

        &:focus {
            -webkit-box-shadow: 0 0 0 1000px $white-grey inset !important;
        }
    }

    // Size options

    &.small {
        min-height: rem-calc(24px) !important;
    }
}

// Checkbox and Radio ///////////////////////////////////////////////////////////////////////////////

.checkbox, .radio {
    display: flex;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
    font-weight: bold;
    min-height: inherit;

    .label-choice {
        display: inline-block;
        margin: rem-calc(0 8px);
    }
}

.radio .custom-radio {
    display: flex;
    justify-content: center;
    align-items: center;
}

input[type="radio"],
input[type="checkbox"] {
    display: none;
}

input[type="radio"] + span,
input[type="checkbox"] + span {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    height: rem-calc(25px);
    width: rem-calc(25px);
    cursor: pointer;

    i {
        position: absolute;
        top: rem-calc(-4px);
        left: rem-calc(-25px);
        font-size: rem-calc(12px);
        color: $medium-grey;
    }
}

input[type="checkbox"] + span:before {
    display: block;
    content: '';
    height: rem-calc(24px);
    width: rem-calc(24px);
    border: rem-calc(2px) solid $app-color;
}

input[type="radio"] + span:before {
    display: block;
    content: '';
    height: rem-calc(20px);
    width: rem-calc(20px);
    border: rem-calc(2px) solid $medium-grey;
    border-radius: 100%;
}

input[type="radio"]:disabled + span:before,
input[type="checkbox"]:disabled + span:before {
    border-color: $disable;
    cursor: not-allowed;
    opacity: 0.4;
}

input[type="checkbox"] + span:after {
    position: absolute;
    font-family: 'Font Awesome 5 Free';
    content: '\f00c';
    font-weight: 900;
    top: rem-calc(-1px);
    left: rem-calc(7px);
    color: $white;
    font-size: rem-calc(11px);
    transform: scale(0) !important;
    transition: 200ms;
}

input[type="radio"] + span:after {
    position: absolute;
    content: '';
    height: rem-calc(12px);
    width: rem-calc(12px);
    background-color: $app-color;
    font-size: rem-calc(16px);
    transform: scale(0) !important;
    border-radius: 100%;
    transition: 200ms;
}

input[type="radio"]:checked + .custom-radio + .label-choice {
    color: $app-primary !important;
}

input[type="checkbox"]:checked + .custom-checkbox + .label-choice {
    color: $app-color !important;
}

input[type="radio"]:checked + span:before {
    position: relative;
    display: inline-block;
    content: '';
    height: rem-calc(20px);
    width: rem-calc(20px);
    margin-right: 0 !important;
    background: rgba($app-primary, 0.1);
    color: $white;
    border: rem-calc(1px) solid rgba($app-primary, 0.1);
}

input[type="checkbox"]:checked + span:before {
    background: white;
    border: rem-calc(2px) solid $app-color;
}

input[type="radio"]:checked:disabled + span:before,
input[type="checkbox"]:checked:disabled + span:before {
    background: $medium-grey;
    cursor: not-allowed;
}

input[type="checkbox"]:checked + span:after {
    position: absolute;
    font-family: 'Font Awesome 5 Free';
    content: '\f00c';
    font-weight: 900;
    top: rem-calc(2px);
    left: rem-calc(6px);
    color: $app-color;
    font-size: rem-calc(12px);
    transform: scale(1.4) !important;
    transition: 200ms;
}

input[type="checkbox"]:indeterminate + span:after {
    position: absolute;
    font-family: 'Font Awesome 5 Free';
    content: '\f068';
    font-weight: 900;
    top: rem-calc(2px);
    left: rem-calc(7px);
    color: $app-color;
    font-size: rem-calc(12px);
    transform: scale(1.4) !important;
    transition: 200ms;
}

input[type="checkbox"]:checked:disabled + span:after {
    color: rgba($app-color, 0.5);
    cursor: not-allowed;
}

input[type="radio"]:checked + span:after {
    position: absolute;
    content: '';
    height: rem-calc(12px);
    width: rem-calc(12px);
    background-color: $app-color;
    font-size: rem-calc(16px);
    transform: scale(1) !important;
    transition: 200ms;
}

input[type="radio"]:checked:disabled + span:after {
    color: $medium-grey;
    cursor: not-allowed;
}

input[type="checkbox"]:checked + .custom-checkbox,
input[type="radio"]:checked + .custom-radio {
    height: rem-calc(26px);
    width: rem-calc(25px);

    i {
        color: $app-primary;
    }
}

// Date Picker ///////////////////////////////////////////////////////////////////////////////

.date-picker {
    display: flex;
    flex-direction: column;
}


// Input Group ///////////////////////////////////////////////////////////////////////////////

.input-group {
    margin: rem-calc(0 0 0 8px);


    &:disabled {
        border-color: $disable;

        .input-group-label {
            background: $disable;
        }
    }

    .input-group-field {
        border-radius: 4px;
    }

    .input-group-label {
        border: 0;
        height: rem-calc(48px);
        background: white;
        color: $app-primary;
    }
}

// Card divider form ///////////////////////////////////////////////////////////////////////////////

.card-divider {
    label {
        color: $app-primary;
    }
}


// Skin Fieldset ///////////////////////////////////////////////////////////////////////////////

fieldset {
    border: rem-calc(1px) solid rgb(198, 198, 198);
    font-size: rem-calc(16px);
    margin-bottom: rem-calc(16px);

    legend {
        padding: rem-calc(0 8px);
    }
}


// Skin Phone Number ///////////////////////////////////////////////////////////////////////////////

int-phone-number-input {
    .iti.iti--allow-dropdown.separate-dial-code.iti-sdc-3 {
        width: 100%;
    }
}

