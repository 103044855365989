* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
    font-size: rem-calc(16px);
}

html, body {
  height: 100%;
}

body {
  font-size: rem-calc(16px);

  @include breakpoint(medium) {
    font-size: rem-calc(12px);
  }
}

img:focus, li:focus, div:focus, .ng-select:focus {
 outline: none;
}

hr {
  margin: rem-calc(8px 0);
}

.title-icon {
  width: 60px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0, .2);
  border-radius: 100%;
  height: 60px;
  background-color: white;
  display: block;
}

[hidden], .hidden {
  display: none !important;
}

.disabled {
  cursor: not-allowed !important;

  &:after {
    cursor: not-allowed !important;
  }
}
