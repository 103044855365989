.toast-container {
  max-width: rem-calc($max-width-app);
  min-width: rem-calc($min-width-app);
  width: 100%;
  top: calc(#{$header-height} + 48px) !important;
  left: 50% !important;
  transform: translate(-50%, 0);
  font-size: rem-calc(14px);
  font-weight: bolder;

   .ngx-toastr {
     width: auto;

     &:after {
       font-family: 'Font Awesome 5 Free';
       font-weight: 900;
       position: absolute;
       right: 0;
       top: 0;
       height: 100%;
       width: rem-calc(48px);
       content: '\f00d';
       color: $white;
       font-size: rem-calc(24px);
       line-height: rem-calc(48px);
       display: flex;
       justify-content: center;
       align-items: center;
     }
   }

  .toast {
    width: auto !important;
    box-shadow: none;
    margin: 0 !important;
    left: 0 !important;
    right: 0 !important;
    padding-right: rem-calc(74px) !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    font-weight: bold;
    text-align: center !important;
    height: rem-calc(48px);
    display: flex;
    align-items: center;
  }



  &:hover {
    @extend %transition;
    box-shadow: none !important;

    &.toast-error { background-color: $app-error;}
    &.toast-info { background-color: lighten($app-secondary, 5%);}
    &.toast-success { background-color: lighten($app-success, 5%);}
    &.toast-warning { background-color: lighten($app-warning, 5%);}
  }

  .toast-error { border-bottom: rem-calc(2px) solid $app-error; background-color: $app-error; &:after {background-color: darken($app-error, 5%);}}
  .toast-info { border-bottom: rem-calc(2px) solid darken($app-secondary, 5%); background-color: $app-secondary; &:after {background-color: darken($app-secondary, 5%);}}
  .toast-success { border-bottom: rem-calc(2px) solid darken($app-success, 5%);  background-color: $app-success; &:after {background-color: darken($app-success, 5%);}}
  .toast-warning { border-bottom: rem-calc(2px) solid darken($app-warning, 5%); background-color: $app-warning; &:after {background-color: darken($app-warning, 5%);}}
}
