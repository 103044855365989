@charset "utf-8";

// ~ IMPORT ALL SASS
// ====================================================================== >
// For more informations on sass organization, to know where to put which files, etc.
// see : http://sass-guidelin.es/fr/#le-pattern-7-1

// If you have doubts, put your declarations at the end of this file. I will move these later.

// FOR FONT AWESOME 5 CLASSES SEARCH : go to https://fontawesome.com/icons?d=gallery&m=free
// I advice to click on icon and take DOM given by the site. In new fontawesome version the class isn't always `.fa`

// ~ UTILS
// ---------------------------------------------------------------------- >
@import 'utils/functions';
@import 'utils/variables';
@import 'utils/status-variables';
@import 'utils/mixins';
@import 'utils/helpers';
@import 'utils/placeholders';
@import 'utils/fontIcon-pro';
@import 'utils/font-EDF';
@import 'utils/font-BNPP_Sans';
@import 'utils/colors';

// ~ VENDORS
// ====================================================================== >
$contrast-warnings : false;
@import 'vendors/foundation-variables';
@import '../../../../node_modules/foundation-sites/scss/foundation';
@include foundation-everything($flex: true);
@import url('https://fonts.googleapis.com/css?family=Work+Sans');
@import 'vendors/sweet-alert';

// ~ BASE
// --------------------------------------------x-------------------------- >
@import 'base/base';
@import 'base/typographies';
@import 'base/content';
@import 'base/icons';

// ~ COMPONENTS
// ---------------------------------------------------------------------- >
@import 'components/add-icon';
@import 'components/animations';
@import 'components/date-picker';
@import 'components/buttons';
@import 'components/form';
@import 'components/step-indicator';
@import 'components/tables';
@import 'components/tabs';
@import 'components/pagination';
@import 'components/pass-indicator';
@import 'components/offers-bloc';
@import 'components/subscription-bloc';
@import 'components/progress';
@import 'components/toaster';
@import 'components/indicators';
@import 'components/status';
@import 'components/loader';
@import 'components/ngSelect';
@import 'components/ngSlider';
@import 'components/switch';
@import 'components/iziviaPopup';
@import 'components/theme';

// ~ PAGES
// ---------------------------------------------------------------------- >
@import 'pages/app-pages';
@import 'pages/register';
@import 'pages/account';
@import 'pages/expense-report-search';
@import 'pages/tariff-form';

// ~ LAYOUT
// ---------------------------------------------------------------------- >
@import 'layout/main-layout';
