// -----------------------------------------------------------------------------
// This file contains all styles related to the APP LOADER components.
// ----------------------------------------------------------------------------

// ==== App Loader ==== //

.app-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: rem-calc(120px);
  height: 0;

  &.small {
    width: rem-calc(50px);
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: rem-calc(64px);
    width: rem-calc(64px);
    background: url($svg-path + "spinner.svg");
    transform: translate(-50%, -100%) rotate(135deg);
    background-size: 100%;

    &.small {
      height: rem-calc(34px);
      width: rem-calc(34px);
    }
  }

  .title-spinner {
    margin-left: rem-calc(12px);
    letter-spacing: rem-calc(2px);
    color: $app-color;
  }
}
