a {
    &.button {
        text-decoration: none;
        border-radius: $global-radius;
    }
}

.button {
    min-width: rem-calc(160px);
    font-size: rem-calc(14px);
    font-weight: bold;
    padding: rem-calc(16px);
    margin: auto 0;
    @extend %rippleEffect;
    transition: all 300ms;
    background: $app-color;
    color: white;
    border: 2px solid transparent;

    &:hover {
        opacity: 0.5;
        background: $app-color;
    }

    // Colors options

    &.white {
        background-color: white;
        color: $app-color;
        border: 2px solid white;
    }

    &.white-color {
        background-color: white;
        color: $app-color;
        border: 2px solid white;

        &:hover {
            background: $app-color;
            color: white;
            border: 2px solid white;
        }
        &:focus,
        &:active{
            background-color: white;
            color: $app-color;
            border: 2px solid white;
        }
    }

    &.primary-transparent {
        background-color: rgba($app-color, 0.2);
        color: $app-color;

        &:hover {
            background-color: rgba($app-color, 0.4);
        }
        &:focus,
        &:active{
            background-color: rgba($app-color, 0.2);
            color: $app-color;
        }
    }

    &.primary {
        background-color: $app-color;
        border: 2px solid $app-color;
        color: white;

        &:hover {
            opacity: 0.5;
            background-color:$app-color;
        }

        &:focus,
        &:active{
            background-color: $app-color;
            border: 2px solid $app-color;
            color: white;
        }
    }

    &.secondary,
    &.eye,
    &.color {
        border: 2px solid $app-color;
        color: $app-color;
        background: white;

        i, span {
            color: $app-color;
        }

        &:hover {
           opacity: 0.5;
            background: white;
            color: $app-color;
        }
        &:focus,
        &:active{
            border: 2px solid $app-color;
            color: $app-color;
            background: white;

            i, span {
                color: $app-color;
            }
        }

        &[disabled], &.disabled {
            background: $disable;
            color: white;
            cursor: not-allowed;
            opacity: 1;
            border: 0;

            &:hover {
                background: $disable;
            }
        }
    }

    &.secondary-color {
        background-color: $app-color;

        &:hover {
            background-color: darken($app-color, 8%);
        }
    }

    &.color-light {
        background: $app-color-light;

        &:hover {
            opacity: 0.2;
        }
    }

    &.color-transparent {
        background-color: rgba($app-color, 0.2);
        color: $app-color;

        &:hover {
            background-color: rgba($app-color, 0.4);
        }
    }

    // Disabled skin

    &.white,
    &.white-color,
    &.primary-transparent,
    &.primary,
    &.secondary,
    &.secondary-color,
    &.color,
    &.color-light,
    &.color-transparent {
        &[disabled], &.disabled {
            background-color: $disable;
            color: white;
            border-color: $disable;
            opacity: 1;
            cursor: not-allowed;

            &:hover {
                background-color: $disable;
                color: white;
                border-color: inherit;
            }
        }
    }

    &[disabled], &.disabled {
        background: $disable;
        border-color: $disable;
        color: white;
        cursor: not-allowed;
        opacity: 1;

        &:hover {
            background: $disable;
        }
    }

    // Shape options

    &.icon-btn {
        background: transparent;
        color: $app-color;
        height: rem-calc(34px);
        width: rem-calc(34px);
        min-width: inherit;
        border-radius: 100%;
        padding: rem-calc(4px);
        @extend %transition;

        &:hover, &:active {
            @extend %transition;
            background: $app-color;
            color: $white;
        }

        &.left {
            margin-right: rem-calc(4px);
            border-right: 2px $app-color solid !important;

            &:hover, &:active {
                border-right: 2px $app-color solid !important;
            }
        }

        &.right {
            margin-left: rem-calc(4px);
            border-left: 2px $app-color solid !important;

            &:hover, &:active {
                border-left: 2px $app-color solid !important;
            }
        }

        &.primary {
            border: 2px solid $app-color;
        }
    }

    &.round {
        min-width: rem-calc(52px);
        height: rem-calc(52px);
        border-radius: 100%;
    }

    &.small {
        font-size: inherit;
        padding: rem-calc(8px);
        min-width: rem-calc(40px);
        height: rem-calc(40px);
    }

    &.tiny {
        display: flex;
        justify-content: center;
        align-items: center;
        height: rem-calc(12px);
        width: rem-calc(12px);
        min-width: rem-calc(12px);
        font-size: rem-calc(11px);

        &._withText {
            width: fit-content;
            gap: rem-calc(8px);
        }
    }

    &._fullWidth {
        width: 100%;
    }

    // Icons options

    &.logout {
        &:before {
            content: '\f2f5';
            font-family: "Font Awesome 5 Free";
            padding-right: rem-calc(16px);
        }
    }

    &.go {
        &:before {
            content: '\f061';
            font-family: "Font Awesome 5 Free";
            padding-right: rem-calc(16px);
        }
    }

    &.validate {
        &:before {
            content: '\f00c';
            font-family: "Font Awesome 5 Free";
            padding-right: rem-calc(8px);
        }
    }

    &.back {
        &:before {
            content: '\f30a';
            font-family: "Font Awesome 5 Free";
            padding-right: rem-calc(16px);
        }
    }

    &.next {
        &:after {
            content: '\f30b';
            font-family: "Font Awesome 5 Free";
            padding-left: rem-calc(16px);
        }
    }

    &.previous {
        &:before {
            content: '\f30a';
            font-family: "Font Awesome 5 Free";
            padding-right: rem-calc(16px);
        }
    }

    &.save {
        &:after {
            content: '\f0c7';
            font-family: "Font Awesome 5 Free";
            padding-left: rem-calc(8px);
        }
    }

    &.add {
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
            content: '\f055';
            font-family: "Font Awesome 5 Free";
            padding-right: rem-calc(16px);
            font-size: rem-calc(18px);
            line-height: 0;
        }

        &[hidden] {
            display: none;
        }
    }

    &.orange {
        background-color: rgba($app-color, 0.1);
        color: $app-color;

        &:hover {
            background-color: rgba($app-color, 0.3);
            transition: all 1s;
        }
    }

    &.remove-pseudo-content {
        &:before, &:after {
            content: none;
        }
    }
}

.moreDetail {
    margin-right: 8px;
    text-decoration: underline;
    color: $app-color;
    cursor: pointer;

    &-arrow {
        font-size: 18px !important;
        margin-left: 8px !important;
    }

    &:hover,
    &:focus {
        color: $app-color;
        opacity: 0.5;
    }

    &.selected {
        opacity: 0.2;
    }

    &-cards {
        position: initial;
        font-size: 20px;
    }
}
