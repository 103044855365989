// App root ////////////////////////////////////////////////////////////////////////
.app-container {
    display: block;
    height: 100vh;
    overflow: hidden;
    padding-top: rem-calc($header-height);
}

.app-header {
    position: fixed;
    height: rem-calc($header-height);
    background-color: white;
    box-shadow: rem-calc(0 3px 6px) $light-grey;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
}

.app-content {
    height: calc(100vh - #{$header-height});
    overflow-y: auto;
    background: $background;
}

.app-view-content {
    min-height: 100%;
    max-width: rem-calc($max-width-app);
    min-width: rem-calc($min-width-app);
    margin: 0 auto;
    background-color: white;
    overflow: hidden;
    overflow-y: auto;

    .has-space {
        padding: rem-calc(16px);
    }
}

.app-view-content::-webkit-scrollbar {
    display: none;
}



.main-container {
    background-color: $white;
    border-radius: $border-radius;
    min-height: 100%;

    &.has-space {
        padding: rem-calc(16px);
    }
}

// Skin block ////////////////////////////////////////////////////////////////////////

.focus-block {
    @extend %box-shadow;
    padding: rem-calc(8px 12px);

    &.read-only {
        background-color: rgba($app-primary, 0.05);
    }

    &.clear-padding {
        padding: 0 !important;
    }
}

.common-block {
    background-color: $white;
    border-radius: $border-radius;
    padding: rem-calc(16px);
    margin-bottom: $app-gutter;
    color: $app-primary;

    &.actions {
        background-color: transparent;
        margin-top: 0;
        padding: 0;
    }
}

.register-block {
    z-index: 1;
    background-color: $white;
    border-radius: $border-radius;
    padding: rem-calc(16px);
    margin-bottom: $app-gutter;
}

.reset-space {
    .register-block {
        padding: 0;
        margin: 0;
    }
}

.update-block {
    z-index: 1;
    background-color: $white;
    border-radius: $border-radius;
    padding: rem-calc(16px);
    margin-bottom: $app-gutter;
}

.reset-space {
    .update-block {
        padding: 0;
        margin: 0;
    }
}

.light-grey-block {
    display: block;
    background-color: $white-grey;
    border: rem-calc(1px) solid $medium-grey;
    border-radius: $border-radius;
    padding: rem-calc(32px);
    margin: rem-calc(16px) 0;
}

.number-block-title {
    max-width: rem-calc(180px);
    margin-bottom: rem-calc(8px);
}

.number-block {
    display: flex;
    justify-content: center;
    border: rem-calc(1px) solid $medium-grey;
    border-radius: $border-radius;
    background-color: $white;
    color: $black;
    font-size: rem-calc(40px);
    font-weight: 600;
    min-width: rem-calc(180px);
    padding: rem-calc(8px);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center left;

    // Colors options

    &.color {
        border: rem-calc(1px) solid $app-color;

        &.time {
            background-image: url("/assets/png/time-color.png");
        }

        &.price {
            background-image: url("/assets/png/price-color.png");
        }
    }

    // Icons options

    &.time {
        background-image: url("/assets/png/time.png");
    }

    // Size Options

    &.small {
        font-size: rem-calc(24px);
        background-position: center left;
        padding: 0;
        min-width: rem-calc(100px);
    }
}

.overloaded-number-block {
    display: flex;
    justify-content: center;
    border: rem-calc(1px) solid $medium-grey;
    border-radius: $border-radius;
    background-color: $white;
    color: $black;
    font-weight: 600;
    min-width: rem-calc(180px);
    padding: rem-calc(8px);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center left;

    // Colors options

    &.color {
        border: rem-calc(1px) solid $app-color;

        &.time {
            background-image: url("/assets/png/time-color.png");
        }

        &.price {
            background-image: url("/assets/png/price-color.png");
        }
    }

    // Icons options

    &.time {
        background-image: url("/assets/png/time.png");
    }

    &.price {
        background-image: url("/assets/png/price.png");
    }

    // Size Options

    &.small {
        font-size: rem-calc(24px);
        background-position: center left;
        padding: 0;
        min-width: rem-calc(100px);
    }
}

// Action bar ////////////////////////////////////////////////////////////////////////

.fix-action-bar {
    min-height: rem-calc(64px) !important;
}

.action-bar {
    background: rgba($app-primary, 0.1);
    padding: rem-calc(8px);
    border-radius: rem-calc(8px);

    &.add-space-block {
        margin: rem-calc(8px 8px 0 8px);
    }
}


// Scroll container ////////////////////////////////////////////////////////////////////////

.scroll-container {
    overflow-y: auto;
}


// Fix class //////////////////////////////////////////////////////////////////////////////

.fix-height {
    height: 100%;
}

.fix-z-index {
    z-index: 1;
    position: relative;
}

.fix-flex {
    display: flex;
}

.fix-overflow-hidden {
    overflow: hidden;
}
