.account-title-bar {
    background: rgba($app-primary, 0.05);
    padding: rem-calc(8px);
    border-radius: rem-calc(8px);
}

.account-list-header {
    background: $app-primary;
    color: $white;
    border: none;
    border-radius: rem-calc(4px);
    height: rem-calc(50px);

    &-item {
        font-size: rem-calc(16px);
        font-weight: bold;
    }
}

.account-list-action {
    min-width: rem-calc(160px);
    text-align: right;
}
