.register-header {
  padding: rem-calc(16px);
  margin-bottom: $app-gutter;
}

.register-form-part {
  border: rem-calc(2px) solid $medium-grey;
  border-radius: $border-radius;
  padding: rem-calc(16px);
  height: 100%;

  &.business {
    margin-top: rem-calc(16px);
    margin-right: rem-calc(24px);
    position: relative;
  }

  &.profile {
    margin-top: rem-calc(16px);
    position: relative;
  }
}
