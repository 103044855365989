.pagination {
  display: flex;
  align-items: center;

  .pagination-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem-calc(28px);
    width: rem-calc(28px);
    border: rem-calc(1px) solid $medium-grey;
    color: $medium-grey;
    border-radius: 100%;
    text-align: center;

    &:disabled {
        pointer-events: none;
        opacity: 0.2;
    }

    &:hover {
      transition: 340ms;
      color: $app-primary;
      border-color: $app-primary;
      background-color: white;
      cursor: pointer;
    }
  }

  .pagination-label {
    margin: 0 rem-calc(8px);
    color: $app-primary;
    font-size: rem-calc(14px);
  }
}
