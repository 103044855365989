.swal2-popup {
    width: rem-calc(516px) !important;
    padding: rem-calc(32px) !important;

    .swal2-html-container {
        span, p {
            color: $app-font-color;
        }
    }
}
