@font-face {
    font-family: 'izivia-mobile';
    src:  url('../../assets/fonts/izivia-mobile.eot?p39jw0');
    src:  url('../../assets/fonts/izivia-mobile.eot?p39jw0#iefix') format('embedded-opentype'),
    url('../../assets/fonts/izivia-mobile.woff2?p39jw0') format('woff2'),
    url('../../assets/fonts/izivia-mobile.ttf?p39jw0') format('truetype'),
    url('../../assets/fonts/izivia-mobile.woff?p39jw0') format('woff'),
    url('../../assets/fonts/izivia-mobile.svg?p39jw0#izivia-mobile') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'izivia-mobile' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-info:before {
    content: "\e902";
}
.icon-invoice:before {
    content: "\e900";
}
.icon-credit:before {
    content: "\e901";
}
