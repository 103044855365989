// -----------------------------------------------------------------------------
// This file contains all styles related to the TABS components. Base style in tabs.component.scss
// ----------------------------------------------------------------------------

.vertical {
  &.tabs {
    @media screen and (min-width:0\0) {
      justify-content: inherit !important;
    }
  }
}
