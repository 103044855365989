// -----------------------------------------------------------------------------
// This file contains all styles related to the STATUS component.
// -----------------------------------------------------------------------------

// ===== Base Status

.status {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: rem-calc(6px 16px 4px 16px);
    border-radius: rem-calc(50px);
    text-align: center;
    font-size: rem-calc(14px);
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @each $status-type, $color in $status-color {
        &.status-color-#{$status-type} {
            color: $color;
            background: rgba($color, 0.2);

            .status-link {
                background: $color;
                color: $white;
            }
        }
    }

    @each $status-type, $color in $collaborator-status-color {
        &.status-color-#{$status-type} {
            color: $color;
            background: rgba($color, 0.2);
            width: 100%;
            font-size: rem-calc(12px);
            padding: rem-calc(6px 12px 4px 12px);

            .status-link {
                background: $color;
                color: $white;
            }
        }
    }

    @each $status-type, $color in $consumption-status-color {
        &.consumption-status-color-#{$status-type} {
            color: $color;
            background: rgba($color, 0.2);

            .status-link {
                background: $color;
                color: $white;
            }
        }
    }

    &.has-link {
        padding-right: rem-calc(34px);
    }

    .status-link {
        position: absolute;
        top: 50%;
        right: rem-calc(2px);
        transform: translate(0, -50%);
        border-radius: 100%;
        height: rem-calc(26px);
        width: rem-calc(26px);
        line-height: rem-calc(28px);
        cursor: pointer;
    }
}

.infraStatus {
    background-color: $white;

    @each $status-type, $color in $station-status-color {
        .color-#{$status-type} {
            color: $color;
        }
        .background-#{$status-type} {
            background: rgba($color, 0.1);
            color: $color;
        }
        .border-#{$status-type} {
            box-shadow: 0 0 0 2px $color;
        }

        @include when('infraStatus-#{$status-type}') {
            box-shadow:none;

            .status-bg-color, .indicator-icon {
                background: $color;
                color: white;
                box-shadow: none;
            }
        }
    }
}
