// -----------------------------------------------------------------------------
// This file contains all styles related to the ANIMATION components common.
// -----------------------------------------------------------------------------

// ==================== //
// Loader Spinner Animation
// ==================== //


@keyframes loadingSpinner {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(380deg);
    }
}

// ==================== //
// Collapsed event
// ==================== //

//.retractable {
//    background-color: $light-grey !important;
//    transition:max-height 0.3s ease-out;
//
//
//    &.retracted {
//        visibility: collapse;
//    }
//}


.retractable {
    background-color: $light-grey !important;

    // Specific context elements
    td {
        overflow: hidden;
    }

    charge-consumptions-details {
        overflow: hidden;
    }

    &.retracted {
        display: none;

        // Specific context elements
        td {
            transition: 440ms ease-out;
        }

    }
}
