// -----------------------------------------------------------------------------
// This file contains all styles related to the PROGRESS component.
// -----------------------------------------------------------------------------

progress {
  background-color: $light-grey !important;
  border-radius: rem-calc(8px);
}

.progress {
  max-width: rem-calc(700px);
  overflow: hidden !important;
    margin-top: 8px;

    &.alert {
        .progress-meter {
            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                background-image: linear-gradient( -45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent );
                z-index: 1;
                background-size: 30px 30px;
            }
        }

        .fillBar {
            &:after {
                left: 75%
            }
        }
    }
}

.progress-meter {
  border-radius: rem-calc(50px);
}

.progress-alert {
    color: $app-color;
}
