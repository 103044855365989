// -----------------------------------------------------------------------------
// This file contains all styles related to the INDICATOR component.
// -----------------------------------------------------------------------------

indicator {

  &.sm-block {

    @include breakpoint(large only) {
      .indicator-content {
        flex-direction: column;

        .indicator-value {
          font-size: rem-calc(14px) !important;
        }

        .indicator-value {
          font-size: rem-calc(12px) !important;
        }
      }
    }
  }

  &.indicator-vrt {
    padding: 0 !important;

    .indicator-container {
      margin: 0;

      @include breakpoint(large only) {
        margin: rem-calc(4px 0);

      }
    }

    .indicator-icon {
    width : 100%;
    border-radius : 5px 5px 0 0;

      @include breakpoint(large only) {
        width: inherit;
      }
    }

    .indicator-content {
      display: block;
      overflow: hidden;

      @include breakpoint(large only) {
        display: inherit;
      }

      .indicator-value, .indicator-label {
        display: block;
        width: 100%;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @include breakpoint(large only) {
          display: inherit;
          text-align: left;
          width: inherit;
        }
      }
    }
  }

  &.color {
    .indicator-container {
      border-color: $app-primary;
    }

    .indicator-icon {
      background-color: $app-primary;
    }
  }
}

.indicator-container {
    position : relative;
  border-radius: $border-radius;
  margin: rem-calc(8px);
  font-weight: bold;
  box-shadow : 0 0 0 2px $app-primary;

  &.color {
   box-shadow : 0 0 0 2px $app-primary;

   .indicator-icon {
   background-color: $app-primary;
   }
  }

    &.primarydark {
           box-shadow : 0 0 0 2px $app-primary-hover;


        .indicator-icon {
            background-color: $app-primary-hover;
        }
    }

  &.secondary {
     box-shadow : 0 0 0 2px $app-secondary;

     .indicator-icon {
       background-color: $app-secondary;
     }
   }

  &.success {
    box-shadow : 0 0 0 2px $app-success;

    .indicator-icon {
      background-color: $app-success;
    }
  }

  &.warning {
     box-shadow : 0 0 0 2px $app-warning;

    .indicator-icon {
      background-color: $app-warning;
    }
  }

  &.error {
    box-shadow : 0 0 0 2px $app-error;

    .indicator-icon {
      background-color: $app-error;
    }
  }

  .indicator-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $app-primary;
    color: $white;
    padding: rem-calc(8px);
    min-width: rem-calc(48px);
    font-size: rem-calc(18px);
    border-radius : 5px;
      margin-right: rem-calc(8px);

    .svg-inline--fa {
     opacity: 0.8;
    }

    &.charge {
      &:after {
        content: '\f5e7';
        font-family: "Font Awesome 5 Free";
        font-size: rem-calc(24px);
        opacity: 0.8;
        line-height: 1;
      }
    }

    &.time {
      &:after {
        content: '\f017';
        font-family: "Font Awesome 5 Free";
        font-size: rem-calc(24px);
        opacity: 0.8;
        line-height: 1;
      }
    }

    &.price {
      &:after {
        content: '\f153';
        font-family: "Font Awesome 5 Free";
        font-size: rem-calc(24px);
        opacity: 0.8;
        line-height: 1;
      }
    }

    &.energy {
      &:after {
        content: '\f0e7';
        font-family: "Font Awesome 5 Free";
        font-size: rem-calc(24px);
        opacity: 0.8;
        line-height: 1;
      }
    }

      &.availableStations, &-AVAILABLE {
          &:after {
              content: '\f00c';
              font-family: "Font Awesome 5 Free";
              font-size: rem-calc(24px);
              opacity: 0.8;
              line-height: 1;
          }
      }

      &.chargingStations, &-CHARGING {
          &:after {
              content: '\f1e6';
              font-family: "Font Awesome 5 Free";
              font-size: rem-calc(24px);
              opacity: 0.8;
              line-height: 1;
          }
      }

      &.reservedStations, &-RESERVED {
          &:after {
              content: '\f017';
              font-family: "Font Awesome 5 Free";
              font-size: rem-calc(24px);
              opacity: 0.8;
              line-height: 1;
          }
      }

      &.servicingStations, &-SERVICING {
          &:after {
              content: '\f7d9';
              font-family: "Font Awesome 5 Free";
              font-size: rem-calc(24px);
              opacity: 0.8;
              line-height: 1;
          }
      }

      &.unavailableStations, &-UNAVAILABLE {
          &:after {
              content: '\f00d';
              font-family: "Font Awesome 5 Free";
              font-size: rem-calc(24px);
              opacity: 0.8;
              line-height: 1;
          }
      }

      &.unavailableStations, &-OCCUPIED {
          &:after {
              content: '\f5de';
              font-family: "Font Awesome 5 Free";
              font-size: rem-calc(24px);
              opacity: 0.8;
              line-height: 1;
          }
      }

    &.passDisabled {
      &:after {
        content: '\f057';
        font-family: "Font Awesome 5 Free";
        font-size: rem-calc(18px);
        opacity: 0.8;
        line-height: 1;
      }
    }

    &.passActivated {
      &:after {
        content: '\f058';
        font-family: "Font Awesome 5 Free";
        font-size: rem-calc(18px);
        opacity: 0.8;
        line-height: 1;
      }
    }

    &.passOrders {
      &:after {
        content: '\f291';
        font-family: "Font Awesome 5 Free";
        font-size: rem-calc(18px);
        opacity: 0.8;
        line-height: 1;
      }
    }
      &.passSubscription {
      &:after {
        content: '\f2c2';
        font-family: "Font Awesome 5 Free";
        font-size: rem-calc(18px);
        opacity: 0.8;
        line-height: 1;
      }
    }

    &.chargeNow {
      &:after {
        content: '\f5e7';
        font-family: "Font Awesome 5 Free";
        font-size: rem-calc(18px);
        opacity: 0.8;
        line-height: 1;
      }
    }

    &.chargeLastWeek {
      &:after {
        content: '\f017';
        font-family: "Font Awesome 5 Free";
        font-size: rem-calc(18px);
        font-weight: normal;
        opacity: 0.8;
        line-height: 1;
      }
    }

    &.chargeLastMonth {
      &:after {
        content: '\f073';
        font-family: "Font Awesome 5 Free";
        font-size: rem-calc(18px);
        font-weight: normal;
        opacity: 0.8;
        line-height: 1;
      }
    }

    &.lastMonthPayed {
      &:after {
        content: '\f1da';
        font-family: "Font Awesome 5 Free";
        font-size: rem-calc(18px);
        font-weight: 900;
        opacity: 0.8;
        line-height: 1;
      }
    }

    &.thisMonthPayed {
      &:after {
        content: '\f274';
        font-family: "Font Awesome 5 Free";
        font-size: rem-calc(18px);
        font-weight: normal;
        opacity: 0.8;
        line-height: 1;
      }
    }

    &.thisMonthToPay {
      &:after {
        content: '\f570';
        font-family: "Font Awesome 5 Free";
        font-size: rem-calc(18px);
        font-weight: 900;
        opacity: 0.8;
        line-height: 1;
      }
    }

    &.expenseReportToTreat {
      &:after {
        content: '\f0c0';
        font-family: "Font Awesome 5 Free";
        font-size: rem-calc(18px);
        font-weight: 900;
        opacity: 0.8;
        line-height: 1;
      }
    }

    &.car {
      &:after {
        content: '\f1b9';
        font-family: "Font Awesome 5 Free";
        font-size: rem-calc(18px);
        opacity: 0.8;
        line-height: 1;
      }
    }

    &.users {
      &:after {
        content: '\f0c0';
        font-family: "Font Awesome 5 Free";
        font-size: rem-calc(18px);
        opacity: 0.8;
        line-height: 1;
      }
    }

      &.receipt {
          &:after {
              content: '\f543';
              font-family: "Font Awesome 5 Free";
              font-size: rem-calc(18px);
              opacity: 0.8;
              line-height: 1;
          }
      }
  }

  .indicator-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: rem-calc(4px 8px);
    color: $app-primary;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 48px);

    .indicator-value {
      position : relative;
      font-size: rem-calc(16px);
      margin-right: rem-calc(6px);
      font-weight: bold;
    }

    .indicator-label {
      font-weight: normal;
      overflow: hidden;
      text-overflow: ellipsis;
        font-size: 14px;
    }
     @include breakpoint(large only) {
            .indicator-label {
                 font-size: rem-calc(12px);
            }
        }
  }
}
