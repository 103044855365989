
.icon-custom {

  // Size
  &.block-title-icon {
    img {
      height: rem-calc(24px);
      width: rem-calc(24px);
    }
  }

  &.small {
    img {
      height: rem-calc(34px);
      width: rem-calc(34px);
    }
  }
}