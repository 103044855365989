$status-color : (
    'CHARGING': $app-warning,
    'WAITING_REFUND': #005bbb,
    'AUTOMATIC_REFUND_IN_PROGRESS': #005bbb,
    'PAYMENT_ERROR': #e23737,
    'IN_PROGRESS' : #dba700,
    'INITIAL' : #ffa02f,
    'COLLECTING' : #4361ee,
    'INVOICED' : $app-secondary,
    'INVOICING' : #4387b5,
    'COLLECTED' : $app-success,
    'REFUNDED' : #509e2f,
    'PAID' : $app-success,
    'SUCCESS' : $app-success,
    'ERROR' : $app-error,
    'CANCELLED' : #fa0f1c,
    'DRAFT' : #dfb504,
)!default;

$consumption-status-color : (
    'NEW': $app-warning,
    'INVOICED' : $app-secondary,
    'INVOICING' : #4387b5,
)!default;

$station-status-color : (
    'AVAILABLE': $app-success,
    'CHARGING': $app-secondary,
    'RESERVED': $app-color-light,
    'SERVICING': $app-primary-hover,
    'OCCUPIED': $app-warning,
    'UNAVAILABLE': $app-error
)!default;

$collaborator-status-color : (
    'WAITING_FOR_ACTIVATION': $app-warning,
    'ACTIVE': $app-success,
    'INACTIVE': $app-error
)
