@import '../components-dependencies';

.iziviaPopup {
    &-header {
        display: flex;
        flex-direction: column;
        align-items: center;

        &_icon {
            color: $app-color;
            border: 2px solid $app-color;
            border-radius: 100%;
            padding: 1.2rem;
            height: 6.4rem;
            width: 6.4rem !important;
            margin-bottom: 0.8rem;
        }

        &_title {
            max-width: 100%;
            margin: 0 0 0.4em;
            padding: 0;
            color: $popup-title-color;
            font-size: rem-calc(20px);
            font-weight: 600;
            text-align: center;
            text-transform: none;
            word-wrap: break-word;
        }
    }

    &-content {
        display: block;
        justify-content: center;
        text-align: center;
        color: $dark-gray;
        font-size: rem-calc(16px);
        font-weight: 400;
        line-height: normal;
        word-wrap: break-word;

        &_textarea {
            display: flex;
            height: 6.75em;
            padding: 0.75em;
            width: 100%;
            transition: border-color .3s,box-shadow .3s;
            border: 1px solid #d9d9d9;
            border-radius: 0.1875em;
            background: inherit;
            box-shadow: inset 0 1px 1px rgb(0 0 0 / 6%);
            color: inherit;
            font-size: rem-calc(14px);
        }

        &_input {
            width: 100%;
            transition: border-color .3s,box-shadow .3s;
            border: 1px solid #d9d9d9;
            border-radius: 0.1875em;
            background: inherit;
            box-shadow: inset 0 1px 1px rgb(0 0 0 / 6%);
            color: inherit;
            font-size: rem-calc(14px);
        }

        &_message {
            background: rgba(251, 89, 40, 0.15);
            color: $app-color;
            font-weight: 600;
            font-size: 1.4rem;
            padding: 1.6rem;
            border-radius: 0.4rem;
            margin-top: 1.6rem;
            margin-bottom: rem-calc(8px);
        }
    }

    &-buttons {
        display: flex;
        justify-content: center;
        margin: 1.25em auto 0;

        .withMargin {
            margin: rem-calc(0 4px);
        }
    }
}
