/**
* Custom CSS Variables for configuring theme appearance / look
**/

// Typographie
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,500&family=Playfair:wght@300&family=Roboto:wght@400;500;700&display=swap');


// Cards


// Forms
label span, label .labelName {
    color: $app-font-color;
}

label {
    position: relative;

    .labelName, label-choice {
        color: $app-font-color !important;
    }

    &.required {
        input {
            border: 1px solid $dark-grey;
            background: white !important;
            color: $app-font-color;

            &::placeholder {
                color: $dark-grey !important;
                background: white;
            }
        }
    }

    &.invalid {
        background: initial;

        span, .labelName {
            color: $app-error;

            &:after {
                color: $app-error;
            }
        }
    }

    &.validate {
        color: $app-font-color;

        input,
        select,
        .customFile {
            border-color: $dark-grey !important;
            color: $app-font-color;
        }
    }

    .search-icon {
        color: $dark-grey !important;
    }

    &.required {
        input {
            border: 1px solid $dark-grey;
            color: $app-font-color;
        }
    }

}

input[type='text'],
input[type='password'],
input[type='number'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='email'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='url'],
input[type='color'],
input[type='file'],
[type=text],
[type=password],
[type=date],
[type=datetime],
[type=datetime-local],
[type=month],
[type=week],
[type=email],
[type=search],
[type=tel],
[type=time],
[type=url],
[type=color],
textarea,
select,
input {
    color : $app-font-color !important;
    border-color: $dark-grey;

    &:active,
    &:focus {
        border-color: $app-primary !important;
        background: white !important;
    }

    &:hover {
        background: white !important;
        color: $app-font-color !important;

        &:disabled {
            background: white !important;
            color: $dark-grey !important;
        }

        &::placeholder {
            color: $dark-grey !important;
        }
    }

    &::placeholder {
        font-style: normal;
        color: $dark-grey !important;
    }

    &.ng-validate {
        border-color: $dark-grey;
    }

    &.ng-dirty {
        border-color: $app-error;
        color: $app-font-color !important;
    }
}

.search-label.validate .search-icon {
    color: $dark-grey;
}

.note.error {
    color: $app-error;

    &:before {
        color: $app-error;
    }
}

.hiddenError {
    display: none !important;
}

// Ng Select
.validate {
    .ng-select {
        .ng-select-container {
            border-color: $dark-grey;
        }
    }
}

.ng-select {
    .ng-select-container {
        border-color: $dark-grey !important;

        &:active,
        &:focus {
            border-color: $app-primary !important;
            background: white !important;
            color: $app-font-color !important;
        }

        &:hover {
            color: $app-font-color !important;
            background-color: white !important;

            &:disabled {
                background: white !important;
                color: $dark-grey !important;
            }

            &::placeholder {
                color: $app-font-color !important;
            }
        }
    }
}

// Checkbox
input[type=checkbox] + span:before {
    border-radius: rem-calc(4px);
}

input[type=checkbox]:checked + span:before {
    background-color: $app-secondary;
}

input[type=checkbox]:checked + span:after {
    color: white;
}

// Buttons
.button,
a.button {
    border-radius: rem-calc(8px) !important;

    &.round {
        border-radius: 100% !important;
    }
}

a {
    font-weight: normal;
}

.switch-paddle {
    background-color: $dark-grey;

    .switch-active {
        color: white;
    }

    .switch-inactive {
        color: white;
    }
}

// Tabs
.tabs-round-block {
    .tabs-title {
        a:hover {
            color: white !important;
        }
    }
}

// Tabs
.tariffElementTab {
    color: $app-font-color !important;

    &.selected {
        background: $app-secondary !important;
        color : white !important;
    }
}


// GENERAL //

.primary-nav-item-text {
    color: $app-font-color;
    
    &.selected {
        color: $app-secondary;
    }
}

.login {
    .label-container {
        color: white;
    }
}

.passwordContainer {
    &.validate {
        &:before {
            right: rem-calc(60px);
        }
    }
}

.indemnity-date {
    color: $dark-grey !important;
}

