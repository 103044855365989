// ==================== //
// Step indicator
// ==================== //
.progress-indicator {
    position: relative;
    padding: rem-calc(32px) 0;
    counter-reset: item;
    width: 60%;
    margin: 0 auto;

    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        border: 1px solid $app-primary;
        left: 50px;
        right: 50px;
    }

    @media screen and (min-width: 400px) and (max-width: 1080px) {
        width: 100%;
    }
}

step {
    position: relative;

    &:last-child {
        .progress-indicator-item {
            &:after {
                width: 0;
                left: 0;
            }
        }
    }

    .progress-indicator-item {
        font-size: rem-calc(14px);
        font-weight: 600;
        list-style: none;
        color: $app-primary;
        line-height: 1.4;
        position: relative;
        padding-left: rem-calc(66px);
        padding-right: rem-calc(8px);
        text-align: left;
        background: $background;
        border-left: 8px solid $background;
        z-index: 2;

        &:before {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            top: calc(50% - 22px);
            left: 10px;
            height: rem-calc(44px);
            width: rem-calc(44px);
            content: counter(item);
            counter-increment: item;
            border-radius: 100%;
            text-align: center;
            font-size: rem-calc(24px);
            line-height: 40px;
            border: rem-calc(1px) solid $app-primary;;
            color: $app-primary;;
            background-color: $white;
            z-index: 3;
        }

        // ==== State Progress Indicator Item
        &.current {
            color: $app-primary;;

            &::before {
                background-color: $app-color;
                border: none;
                color: $white;
                box-shadow: -1px 0px 19px 0px rgba($app-color, 0.75);
            }
        }

        &.complete {
            color: $app-primary;

            &::before {
                background-color: white;
                color: $white;
            }

            &:after {
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                bottom: rem-calc(-2px);
                left: rem-calc(20px);
                height: rem-calc(24px);
                width: rem-calc(24px);
                content: '\f00c';
                font-family: "Font Awesome 5 Free";
                font-size: 18px;
                border-radius: 100%;
                text-align: center;
                color: $app-primary;
                background-color: white;
                z-index: 4;
            }
        }

        &.disabled {
            color: $light-grey;

            &::before {
                background-color: $light-grey;
                border: none;
                color: $white;
            }
        }
    }
}
