@import '../components-dependencies';
// -----------------------------------------------------------------------------
// This file contains all styles related to the DATE PICKER component.
// -----------------------------------------------------------------------------

// ==================== //
// Define variables colors for DatePicker
// ==================== //

$primary-datepicker: $app-primary;
$grey-light-datepicker: $light-grey;
$medium-grey-datepicker: $medium-grey;
$dark-grey-datepicker: $dark-grey;


.dp-popup {
  border-radius: rem-calc(3px);
  @extend %box-shadow;
}

// ================ //
// Custom Styles Date Picker
// ================ //

date-picker {
  &.full-input {
    dp-date-picker {
      display: block;
      width: 100%;
    }
  }
}

.dp-day-calendar-container, .dp-month-calendar-container {
   border: 0;

  // ================ //
  // Header (month & years - control) Date Picker
  // ================ //

  .dp-calendar-nav-container {
    border: 0;
    height: rem-calc(48px);
    margin-bottom: rem-calc(8px);

    //==== Header Month

    .dp-nav-header {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: rem-calc(16px);
      z-index: 1;

      .dp-nav-header-btn {
        padding: rem-calc(8px);
        color: $primary-datepicker;
      }
    }

    //==== Header Control

    .dp-nav-btns-container {
      left: 0;
      width: 100%;

      .dp-current-location-btn {
        left: 0;
        top: rem-calc(20px);
        width: 100%;
        background: none;
        border: none;

        &:after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          content: 'Aujourd\2032hui';
          font-size: rem-calc(10px);
          text-transform: uppercase;
          color: $medium-grey-datepicker;
        }

        &:hover, &:focus, &:active {
          &:after {
            color: $primary-datepicker;
          }
        }
      }

      .dp-calendar-nav-container-left {
        position: absolute;
        left: rem-calc(8px);

        button::before {
          border-radius: 0 rem-calc(3px) 0 0;
          right: rem-calc(-1px);
        }
      }

      .dp-calendar-nav-container-right {
        position: absolute;
        right: rem-calc(8px);

        button::before {
          border-radius: 0 rem-calc(3px) 0 0;
          left: rem-calc(-1px);
        }
      }

      .dp-calendar-nav-left,
      .dp-calendar-nav-right {
        top: rem-calc(-5px);
        height: rem-calc(28px);
        width: rem-calc(28px);
        border: rem-calc(1px) solid $medium-grey-datepicker;
        color: $medium-grey-datepicker;
        border-radius: 100%;
        text-align: center;

        &:hover {
          transition: 340ms;
          color: $primary-datepicker;
          border-color: $primary-datepicker;
        }
      }
    }
  }

  // ================ //
  // Content (days - years) Date Picker
  // ================ //

  .dp-calendar-wrapper {
    padding-bottom: rem-calc(8px);
    border: 0;

    //==== Content Label Days Table

    .dp-weekdays {
        display: flex;
      padding: rem-calc(8px);
      background-color: $grey-light-datepicker;

      .dp-calendar-weekday {
        border: 0;
        text-transform: uppercase;
        font-size: rem-calc(10px);
      }
    }

    //==== Content Number Days Table

    .dp-calendar-week {
      padding: 0 rem-calc(8px);

      .dp-prev-month {
        color: $medium-grey-datepicker;
      }

      .dp-calendar-day {
        color: $dark-grey-datepicker;

        &:hover, &:focus, &:active {
          background: $medium-grey-datepicker;
          color: white;
        }

        &[disabled] {
          color: $light-grey;
          font-style: italic;
          cursor: default;

          &:hover {
            background: none;
          }
        }
      }

      .dp-selected {
        color: white;
        font-weight: bold;
        background-color: $primary-datepicker;
      }
    }

    //==== Content Years Table

    .dp-month-row {
      padding: 0 rem-calc(8px);

      .dp-calendar-month {
        color: $dark-grey-datepicker;
      }
    }
  }
}

// ==== Adjust Input DatePicker

.dp-picker-input {
  background-size: auto rem-calc(16px) !important;
  background-position: calc(30px - 25px) center !important;
  background-repeat: no-repeat !important;
  padding-left: rem-calc(24px) !important;
}

time-picker, date-time-picker {
  .dp-picker-input { background: url('/assets/png/horaires.png');}
}

// increase the size of the input to allow space for the seconds (which cannot be removed)
date-time-picker .dp-picker-input {
  width:105%;
}

date-picker {
  .dp-picker-input { background: url('/assets/png/calendar.png');}
}
