body {
    font-family: 'Noto Sans', sans-serif !important;
    color: $app-font-color;
}

home {
    font-family: 'Noto Sans', sans-serif !important;
}

h1 {
    font-size: rem-calc(34px);
    font-weight: bold;

    &.primary {
        color: $app-primary;
    }

    &.secondary {
        color: $app-secondary;
    }
}

h2 {
    font-size: rem-calc(28px);
    font-weight: bold;
    color: $app-primary;

    &.secondary {
        color: $app-primary;
    }
}

h3 {
    font-size: rem-calc(20px);
    font-weight: bold;

    &.primary {
        color: $app-primary;
    }

    &.secondary {
        color: $app-primary;
    }
}

h4 {
    font-weight: bold;
    font-size: rem-calc(20px);
}

h5 {
    font-weight: bold;
    color: $app-primary;
    line-height: rem-calc(20px);
    font-size: rem-calc(18px);
}

h6 {
    color: $app-primary;
    font-size: rem-calc(16px);
    line-height: rem-calc(22px);

    &.black {
        color: $black;
    }

    &.white {
        color: $white;
    }

    &.color {
        color: $app-color;
    }
}

p {
    color: $app-font-color;
    font-size: rem-calc(16px);
    line-height: rem-calc(25px);
}

a {
    color: $app-color;
    font-weight: 600;
    font-size: rem-calc(16px);
    text-decoration: underline;

    &:hover {
        color: $app-color;
        opacity: 0.5;
    }

    &:disabled {
        pointer-events: none;
        cursor: not-allowed;
    }
}

// ================ //
// Text Color
// ================ //

.primary-text {
    color: $app-primary !important;
}

.white-text {
    color: white !important;
}

// Skin label font ////////////////////////////////////////////////////////////////////////////////////////

.label {
    font-weight: bold;
    font-size: rem-calc(8px);
    color: $app-font-color;
}
