// ====================================================================== >
// ~ UTILS
// ====================================================================== >
.force-font-italic {
  font-style: italic !important;
}

.highlight-text-italic {
  font-style: italic;
}

.force-font-bold {
  font-weight: bold !important;
}

.highlight-text-bold {
  font-weight: bold;
}

.force-font-normal {
  font-weight: normal !important;
}

.text-right {
  text-align: right;
}

.force-text-right {
  text-align: right !important;
}

.text-center {
  text-align: center;
}

.force-text-center {
  text-align: center !important;
}

.text-left {
  text-align: left;
}

.force-text-left {
  text-align: left !important;
}

.text-justify {
  text-align: justify;
}

.force-text-justify {
  text-align: justify !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-none {
  float: none;
}

.element-centered {
  margin: 0 auto;
}

.pos-relative {
  position: relative;
}

.dir-rtl {
  direction: rtl !important;
}

.dir-ltr {
  direction: ltr !important;
}

.dspl-inbl {
  display: inline-block;
}

.force-dspl-inbl {
  display: inline-block !important;
}

.dspl-block {
  display: block;
}

.force-dspl-block {
  display: block !important;
}

.vert-align-top {
  vertical-align: top;
}

.vert-align-middle {
  vertical-align: middle;
}

.vert-align-bottom {
  vertical-align: bottom;
}

.overflow-hidden {
  overflow: hidden;
}

.clear-float {
  clear: both;
  visibility: hidden;
  margin: 0;
}

.no-wrap {
  white-space: nowrap;
}

.no-wrap-link {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.no-margin { margin: 0 !important;}

.no-padding-left { padding-left: 0 !important;}
.no-padding-right { padding-right: 0 !important;}

// ~ Margin helper
$spacing-value : 0, 1, 2, 4, 5, 8, 10, 12, 15, 16, 20, 24, 28, 25, 30, 32, 35, 40, 45, 48, 50, 55, 60, 64;

@each $value in $spacing-value {
  .space-top-#{$value} {
    margin-top: #{$value*0.1}rem;
  }
  .space-right-#{$value} {
    margin-right: #{$value*0.1}rem;
  }
  .space-bottom-#{$value} {
    margin-bottom: #{$value*0.1}rem;
  }
  .space-left-#{$value} {
    margin-left: #{$value*0.1}rem;
  }
  .space-all-#{$value} {
    margin: #{$value*0.1}rem;
  }


  .force-space-top-#{$value} {
    margin-top: #{$value*0.1}rem !important;
  }
  .force-space-right-#{$value} {
    margin-right: #{$value*0.1}rem !important;
  }
  .force-space-bottom-#{$value} {
    margin-bottom: #{$value*0.1}rem !important;
  }
  .force-space-left-#{$value} {
    margin-left: #{$value*0.1}rem !important;
  }
  .force-space-all-#{$value} {
    margin: #{$value*0.1}rem !important;
  }
}

// ~ Padding helper
$inner-spacing-value : $spacing-value;

@each $value in $inner-spacing-value {
  .inner-space-top-#{$value} {
    padding-top: #{$value*0.1}rem;
  }
  .inner-space-right-#{$value} {
    padding-right: #{$value*0.1}rem;
  }
  .inner-space-bottom-#{$value} {
    padding-bottom: #{$value*0.1}rem;
  }
  .inner-space-left-#{$value} {
    padding-left: #{$value*0.1}rem;
  }
  .inner-space-all-#{$value} {
    padding: #{$value*0.1}rem;
  }

  .force-inner-space-top-#{$value} {
    padding-top: #{$value*0.1}rem !important;
  }
  .force-inner-space-right-#{$value} {
    padding-right: #{$value*0.1}rem !important;
  }
  .force-inner-space-bottom-#{$value} {
    padding-bottom: #{$value*0.1}rem !important;
  }
  .force-inner-space-left-#{$value} {
    padding-left: #{$value*0.1}rem !important;
  }
  .force-inner-space-all-#{$value} {
    padding: #{$value*0.1}rem !important;
  }
}

// ~ Min width helper
$min-width-value : 25, 50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300;

@each $value in $min-width-value {
  .min-width-#{$value} {
    min-width: #{$value*0.1}rem;
  }
}

// ~ Max width helper
$max-width-value : 25, 50, 64, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300, 325, 350, 375, 400, 425, 450, 475, 500;
$max-height-value : 25, 50, 64, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300, 325, 350, 375, 400, 425, 450, 475, 500;

@each $value in $max-width-value {
  .max-width-#{$value} {
    max-width: #{$value*0.1}rem;
  }
}

// ~ Min height helper
$min-height-value : $min-width-value;

@each $value in $min-height-value {
  .min-height-#{$value} {
    min-height: #{$value*0.1}rem !important;
  }
}

// ~ Max height helper
$max-height-value : $max-width-value;

@each $value in $max-height-value {
  .max-height-#{$value} {
    max-height: #{$value*0.1}rem;
  }
}
