table {
  thead {
    background: $app-primary;
    color: $white;
    border: none;
    height: rem-calc(40px);

    tr {
      th {
        font-size: rem-calc(16px);
        padding: rem-calc(8px 10px);
      }
    }
  }

  tbody {
    border: none;
      color: $app-primary;

    &.border {
      border: rem-calc(1px) solid $light-grey;
    }

    tr {
      width: 100%;

      &:nth-child(even) {
        background-color: $light-grey;
      }

      &.selected {
        background: $app-color-gradient;
        background-attachment:fixed;
        font-weight: bold;
        color: $white;

        // === Specific Element

        .status { background: $white;}
        a { color: $white;}
      }

      &:hover {
        background-color: rgba($app-secondary, 0.1);
      }

      &.inactive {
        opacity: 0.5;

        &:hover {
          background-color: $white;
          cursor: not-allowed;

          &:nth-child(even) {
            background-color: $light-grey;
          }
        }
      }

      td {
        padding: rem-calc(8px);
      }

      &.separator {
        background-color: transparent;

        td {
          padding: 0;

          hr {
            color: $app-primary;
          }
        }
      }
    }
  }
}
