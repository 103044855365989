@import '../../styles/components-dependencies';

.switch {
    &:disabled {
        opacity: 0.3;
        background: none;
    }
}

.switch-paddle {
    height: rem-calc(28px) !important;
    width: rem-calc(72px) !important;

    &:after {
        top: 50% !important;
        transform: translateY(-50%);
        left: rem-calc(8px) !important;

    }
}

.switch-input:checked ~ .switch-paddle:after {
    left: rem-calc(48px) !important;
}

.switch-input:checked ~ .switch-paddle {
    background: $app-color;
}

.switch-inactive {
    right: 16%;
}

.switch-active {
    left: 16%;
}
