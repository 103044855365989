.offer-selection {
    &:first-child {
        .offer-block {
            margin-left: 0;
        }
    }

    &:last-child {
        .offer-block {
            margin-right: 0;
        }
    }
}

.offer-block {
    position: relative;
    box-shadow: $box-shadow;
    padding: rem-calc(16px);
    height: 100%;
    z-index: 1;
    color: $black;
    background-size: 300px;
    cursor: pointer;
    border: 2px solid transparent;

    &:hover {
        box-shadow: $box-shadow_hover;
        border: rem-calc(2px) solid $app-color;

        .moreDetail {
            a,
            &-arrow {
                opacity: 0.5;
            }
        }
    }

    &:focus,
    &:active {
        box-shadow: $box-shadow_press;
    }

    &.fix-height {
        height: auto;
    }

    &-subtitle-color {
        color: $app-secondary_light;
    }

    &-content {
        font-weight: normal;
    }

    .offer-block-price {
        font-weight: bold;
    }

    .offer-block-overloaded-price {
        font-weight: bold;
        color: $app-primary;
    }

    &.selected {
        border: 2px solid $app-color;
    }
}

.offer-selection-detail {
    background-color: $white-grey;
    border: rem-calc(1px) solid $medium-grey;
    border-radius: $border-radius;
    padding: rem-calc(16px 32px);
    margin: rem-calc(16px);

    ul {
        li {
            font-size: 16px;list-style-type: none;


        &:before {
            content: "\f3c5";
            font-family: "Font Awesome 5 Free";
            color: $app-color;
            font-weight: bold;
            margin-right: rem-calc(3px);
        }
    }}
}

.offer-block-title {
    font-weight: normal;
    margin-bottom: rem-calc(8px);
}
